import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Layout, Typography } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { useToken } from "components/ui-kit/core/token";
import { getAnalytics } from "logic/analytics/analytics";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import React, { useMemo, useState } from "react";
import Measure from "react-measure";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { generateSyntheticUsers } from "store/modules/synthetic-users/actions";
import { ProjectInviteStatus } from "store/modules/projects/types";
import { resetInsightsData } from "store/utils/reset-insights-data";
import { useDrawerContext } from "../contexts/drawer";
import { useSignupRequiredAlert } from "../signup-required-alert/signup-required-alert";
import { DrawerContainer } from "./drawer";
import { PendingInvitesAlert } from "./pending-invites-alert/pending-invites-alert";
import { PlanUpgradeAlert } from "./plan-upgrade-alert/plan-upgrade-alert";
import { PlanUpgradeModal } from "./plan-upgrade-modal/plan-upgrade-modal";
import { TopBar } from "./top-bar";
import { WorkspaceBoard } from "./workspace-board";
import { INSIGHTS_PANEL_TRIGGER_ID } from "./workspace-page-constants";
import { NewUI } from "./new-ui";
import { useSidebarMenuContext } from "components/routing/page/layouts/with-sidebar-menu/context";
import { WorkspaceMode } from "store/modules/workspaces/types";
import { StudyContainerTabs, useStudyContainerContext } from "../contexts/insights-panel";

export const WorkspacePageContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector((state) => state.projects);
  const audiencesIds = useAppSelector((state) => state.audiences.data.selectedAudiencesIds);
  const problemsIds = useAppSelector((state) => state.problems.data.selectedProblemsIds);

  const analytics = getAnalytics();
  const signupRequiredAlert = useSignupRequiredAlert();
  const token = useToken();
  const extraToken = useExtraToken();
  const workspace = useAppSelector((state) => state.workspaces.data);

  const { setStudyContainerTab } = useStudyContainerContext();
  const { setDrawerOpen } = useDrawerContext();
  const { betaUIEnabled } = useSidebarMenuContext();
  const [contentWidth, setContentWidth] = useState<number | undefined>();
  const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);

  const planUpgradeError = useMemo(
    () =>
      workspace?.workspace?.subscriptionId
        ? TransportFailure.PlanUpgradeNeeded
        : TransportFailure.SubscriptionNotFound,
    [workspace?.workspace?.subscriptionId]
  );

  const hasPendingInvites = useMemo(
    () =>
      !!workspace.workspacesList?.filter(
        (workspace) => workspace.status === ProjectInviteStatus.Pending
      )?.length,
    [workspace.workspacesList]
  );

  const onClickOpenInsightsPanel = () => {
    analytics.track("workspace:insights:panel:open");
    setDrawerOpen(true);
  };

  const handleGenerateUsers = (quantity: number) => {
    if (!workspace.workspace?.activeSubscription) {
      return setShowPlanUpgradeModal(true);
    }

    setDrawerOpen(true);
    setStudyContainerTab(StudyContainerTabs.SyntheticUsers);

    resetInsightsData();

    if (projects.data.project?.id && audiencesIds && problemsIds) {
      dispatch(
        generateSyntheticUsers({
          projectId: projects.data.project?.id,
          audiencesIds,
          problemsIds,
          quantity: quantity.toString(),
        })
      );
    }
  };

  return (
    <>
      <style jsx global>{`
        #${INSIGHTS_PANEL_TRIGGER_ID}.has-interviews .ant-btn {
          color: ${token.colorPrimary} !important;
          background-color: ${token.colorPrimaryBg} !important;
        }
        .custom-script-text-area {
          width: 100%;
          background-color: transparent;

          border: none;
          outline: none;

          border: 1px solid;

          border-radius: ${token.borderRadius}px;
        }
      `}</style>
      <PlanUpgradeModal
        error={planUpgradeError} // replace with the appropriate error
        visible={showPlanUpgradeModal}
        setVisible={setShowPlanUpgradeModal}
      />
      <Measure
        bounds
        onResize={(contentRect) => {
          setContentWidth(contentRect.bounds?.width);
        }}
      >
        {({ measureRef }) => (
          <Layout ref={measureRef}>
            <Layout.Content
              style={{
                display: "flex",
                flexDirection: "column",
                height: `100vh`,
              }}
            >
              {/* TODO: handle both UI's based on something */}
              {betaUIEnabled ? (
                <NewUI />
              ) : (
                <>
                  <TopBar onGenerateButtonClick={handleGenerateUsers} />
                  <div style={{ display: hasPendingInvites ? "block" : "none" }}>
                    <PendingInvitesAlert />
                  </div>
                  {workspace.workspaceMode === WorkspaceMode.AnonymousUser ? (
                    <Alert
                      style={{
                        marginTop: token.padding,
                        marginRight: token.paddingLG,
                        marginLeft: token.paddingLG,
                      }}
                      closable
                      type="info"
                      message={
                        <>
                          {
                            "To experience Synthetic Users and unlock all its features, you need to create an account. If you'd like to make changes to the displayed content or add your own inputs, you can "
                          }
                          <Typography.Link
                            role="button"
                            onClick={() => {
                              signupRequiredAlert.showSignupRequiredAlert();
                            }}
                          >
                            create a free account
                          </Typography.Link>
                          {" and start generating your own insights."}
                        </>
                      }
                    />
                  ) : (
                    <PlanUpgradeAlert />
                  )}
                  <div
                    style={{
                      paddingLeft: token.paddingLG,
                      paddingRight: token.paddingLG,
                      paddingBottom: token.paddingLG,
                      marginTop: token.padding,
                      flex: "1 1 auto",
                    }}
                  >
                    <WorkspaceBoard />
                  </div>
                </>
              )}
            </Layout.Content>
            <Layout.Sider
              id={INSIGHTS_PANEL_TRIGGER_ID}
              // className={hasPreviousSummaryData ? "has-interviews" : ""}
              theme="light"
              width={"46px"}
              style={{
                background: "transparent",
                maxHeight: "100%",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <Button
                    style={{
                      position: "fixed",
                      right: "30px",
                      zIndex: 3,
                      backgroundColor: token.colorPrimaryBg,
                      rotate: "90deg",
                      border: "transparent",
                    }}
                    shape="round"
                    onClick={onClickOpenInsightsPanel}
                    icon={<AntIcon component={() => <FontAwesomeIcon icon={faChevronDown} />} />}
                  />
                </div>
                <Button
                  ghost
                  style={{
                    position: "fixed",
                    height: "100%",
                    width: "48x",
                    backgroundColor: token.colorPrimaryBg,
                    boxShadow: extraToken.boxShadowLeft,
                    color: token.colorTextSecondary,
                    borderColor: "transparent",
                  }}
                  onClick={onClickOpenInsightsPanel}
                >
                  <span
                    style={{
                      writingMode: "vertical-rl",
                      textOrientation: "mixed",
                      transform: "rotate(180deg)",
                    }}
                  >
                    Insights Panel
                  </span>
                </Button>
              </div>
            </Layout.Sider>
          </Layout>
        )}
      </Measure>
      <DrawerContainer contentWidth={contentWidth} />
    </>
  );
};
